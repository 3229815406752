@import url('../../assets/less/_variables.less');

.table-contact{
    .contact-row{
        display: flex;
        align-items: center;
        padding: 2px 0;
        .email-icon{
            background: linear-gradient(0.06deg, #70EFFF 0.45%, #5770FF 655.74%);
            width: 18px;
            height: 18px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 7px;
            .icon {
                fill: @white;
                width: 14px;
            }
        }
        .telegram-icon{
            background: #34AADF;
            width: 18px;
            height: 18px;
            border-radius: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 7px;
            .icon {
                fill: @white;
                width: 11px;
            }
        }
        .twitter-icon{
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 7px;
            .icon {
                fill: #1DA1F2;
                width: 18px;
            }
        }
    }
}

.wallet-adress-col{
    display: flex;
    align-items: center;
    padding: 0 4px 0 2px;
}