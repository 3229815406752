@import "../../../assets/less/_variables.less";

.ant-layout-header {
	&.header {
		background: @white;
		color: @secondary-color;
		height: 60px;
		border-bottom: 1px solid rgba(@border-color-base, 0.4);
		display: flex;
		align-items: center;
		.header-inner{
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 1440px;
			width: 100%;
			margin: auto;
			.logo {
				display: flex;
				align-items: center;
				a{
					display: flex;
					align-items: center;
					.icon {
						width: 115px;
						height: 40px;
					}
				}
			}
			.right-link{
				display: flex;
				align-items: center;
				color: #0c3756;
				.icon{
					margin-right: 4px;
					fill: #0c3756;
				}
				&:hover{
					color: rgba(#0c3756, 0.8);
					.icon{
						fill: rgba(#0c3756, 0.8);
					}		
				}
			}
		}
	}
	.ant-btn {
		&.menu-link {
			background: linear-gradient(180deg, #081C15 0%, #d93a45 100%);
			width: 24px;
			height: 24px;
			border-radius: 24px;
			position: absolute;
			left: 15px;
			top: 20px;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 20px;
			z-index: 2;
			@media screen and (min-width:767px) {
				visibility: hidden;
			}
			.icon {
				width: 6px;
				fill: @text-white;
			}
			&:hover,
			&:focus {
				background: linear-gradient(180deg, #d93a45 0%, #081C15 100%);
			}
		}
	}
}