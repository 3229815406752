@import url('../../assets/less/_variables.less');

.grey-box{
    background: #F4F7F9;
    padding: 15px;
    border-radius: 8px;   
    max-width: 580px;
    margin-bottom: 20px;
    ul {
        padding-left: 22px;
        margin-bottom: 0;
        li {
            padding: 2px 0;
        }
    }
}

.border-box{
    background: @white;
    padding: 18px;
    border: 1px solid #000000;
    border-radius: 8px;
    font-size: @font-size-lg;
    margin-bottom: 1rem;
}