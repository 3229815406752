@import "./_variables.less";

html,
body {
	min-height: 100vh;
	font-family: @font-family;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-feature-settings: normal !important;
	font-variant: normal !important;
	font-size: @font-size-base + 2;
	scroll-behavior: smooth;
	padding: 0;
	margin: 0;
	background: @white;
	color: @secondary-color;
}

body {
	& > {
		iframe{
			display: none; 
		}
	}
}

main {
	position: relative;
}

a {
	text-decoration: none;
}

.icon {
	width: 16px;
	height: auto;
	fill: #000;
	transition: fill 0.3s;

	&.icon-white {
		fill: #fff;
	}
}

#root {
	overflow-x: hidden;
}

.anchor-tag {
	cursor: pointer;
}

h1 {
	font-size: 2.5rem;
	font-weight: 800;
}

h2 {
	font-weight: 800;
	font-size: 2rem;
}

h3 {
	font-size: @font-size-xlg + 2;
	font-weight: 600;
}

h4 {
	font-size: 1.1rem;
	font-weight: 600;
}

p {
	font-size: @font-size-base + 2;
}

pre{
	background: #efefef;
	padding: 10px;
	border-radius: 6px;
}

.main-content {
	max-width: 1440px;
	width: 100%;
	margin: auto;
	.ant-layout-sider {
		background: @sidebar-bg;
		width: 100%;
		max-width: 290px;
	}

	.right-content-wrapper {
		min-height: calc(100vh - 95px);
		background: none;
		padding: 15px 45px 0 45px;
		max-height: calc(100vh - 65px);
		overflow-y: auto;
		@media screen and (max-width:767px) {
			min-width: 60vw;
			padding: 20px 15px 0;
		}
		img{
			max-width: 100%;
		}
	}
}

.ant-layout {
	background: none;

	.ant-layout-footer {
		&.main-footer {
			color: @secondary-color;
			border-top: 1px solid rgb(235, 235, 235);
			text-align: left;
			padding: 15px 0;
			height: 30px;
			background: @white;
			margin-top: 3rem;
			@media screen and (max-width:767px) {
				white-space: nowrap;
			}
		}
	}
}

.disable-class {
	pointer-events: none;
}

.ScrollbarsCustom-TrackY {
	background: rgb(220, 220, 220) !important;
	width: 4px !important;

	.ScrollbarsCustom-ThumbY {
		background: #b4b4b4 !important;
	}
}

.ScrollbarsCustom-TrackX {
	background: rgb(220, 220, 220) !important;
	height: 4px !important;

	.ScrollbarsCustom-ThumbX {
		background: #afafaf !important;
	}
}

.ant-table {
	.ant-table-thead{
		th {
			padding: 10px 15px;
			color: #5B6975;
			font-weight: 600;
			background: #F4F7F9;
		}
	}
	.ant-table-tbody{
		tr {
			td{
				padding: 10px 15px;
			}
			&:nth-child(odd) {
				background: #F4F7F9;
			}
			&:nth-child(even) {
				background: @white;
			}
		}
	}
}


::-webkit-scrollbar {
    width: 6px;
	height: 7px;
}

::-webkit-scrollbar-track {
    background-color: rgb(209, 209, 209);
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}